<template>
    <SwContainer
        v-bind="{
            stretched,
            paddingY,
            bgColor,
            bgImage,
            bgOpacity,
        }"
    >
        <div
            :class="{
                [$style['block']]: true,
                [$style['block_inverted']]: inverted
            }"
        >
            <h1
                :class="[$style['block__title'], 'sw-title']"
                v-html="title"
            />

            <div
                :class="[$style['block__text'], 'sw-text']"
                v-html="text"
            />

            <div :class="$style['block__carousel-area']">
                <CarouselRoot
                    v-if="images && images.length"
                    ref="carousel"
                    :class="$style['block__carousel']"
                    :settings="{
                        infiniteScroll: true,
                        itemsToShow: 1,
                        keysControl: false,
                        mouseDrag: true,
                        transition: 400,
                        wheelControl: false
                    }"
                    style="height: auto; max-height: 320px"
                >
                    <CarouselSlide
                        v-for="(src, index) in images"
                        :key="index"
                        :index="index"
                    >
                        <SwImage
                            :class="$style['block__carousel-image']"
                            :src="src"
                            height="320"
                        />
                    </CarouselSlide>
                </CarouselRoot>

                <template v-if="images && images.length > 1">
                    <SwButton
                        :class="[$style['block__carousel-button'], $style['block__carousel-button_left']]"
                        small
                        @click="$refs.carousel.slidePrev()"
                    >
                        <template #leading-icon>
                            <SvgSpriteArrowLeft />
                        </template>
                    </SwButton>

                    <SwButton
                        :class="[$style['block__carousel-button'], $style['block__carousel-button_right']]"
                        small
                        @click="$refs.carousel.slideNext()"
                    >
                        <template #leading-icon>
                            <SvgSpriteArrowRight />
                        </template>
                    </SwButton>
                </template>
            </div>

            <template v-if="noIndex">
                <client-only>
                    <nofollow>
                        <noindex>
                            <span :class="$style['block__button']">
                                <SwButton
                                    v-if="buttonUrl.includes('//')"
                                    :href="buttonUrl"
                                    target="_blank"
                                >
                                    {{ buttonLabel }}
                                </SwButton>

                                <SwButton v-else :to="buttonUrl">
                                    {{ buttonLabel }}
                                </SwButton>
                            </span>
                        </noindex>
                    </nofollow>
                </client-only>
            </template>

            <span v-else :class="$style['block__button']">
                <SwButton
                    v-if="buttonUrl.includes('//')"
                    :href="buttonUrl"
                    target="_blank"
                >
                    {{ buttonLabel }}
                </SwButton>

                <SwButton v-else :to="buttonUrl">
                    {{ buttonLabel }}
                </SwButton>
            </span>
        </div>
    </SwContainer>
</template>

<script>
import 'hooper/dist/hooper.css'

import {
  Hooper as CarouselRoot,
  Slide as CarouselSlide
} from 'hooper'
import SvgSpriteArrowLeft from './TextAndCarousel/sprite-arrow-left.svg'
import SvgSpriteArrowRight from './TextAndCarousel/sprite-arrow-right.svg'

export default {
  name: 'TextAndCarousel',

  components: {
    CarouselRoot,
    CarouselSlide,
    SvgSpriteArrowLeft,
    SvgSpriteArrowRight
  },

  props: {
    title: {
      type: String,
      default: 'Заголовок'
    },

    text: {
      type: String,
      default: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
    },

    images: {
      type: Array,
      default: () => []
    },

    buttonLabel: {
      type: String,
      default: 'Кнопка CTA'
    },

    buttonUrl: {
      type: String,
      default: '/'
    },

    noIndex: {
      type: Boolean,
      default: false
    },

    stretched: {
      type: Boolean,
      default: false
    },

    paddingY: {
      type: String,
      default: 's'
    },

    bgColor: {
      type: String,
      default: '#FFFFFF'
    },

    bgImage: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "../../../../assets/Web.less";

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  position: relative;

  &__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;

    @media screen and (max-width: @bp-mobile-big) { margin-bottom: 40px; }
    @media screen and (max-width: @bp-mobile-small) { margin-bottom: 32px; }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  &_inverted &__title,
  &_inverted &__text {
    color: #FFFFFF;
  }

  &__button {
    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
    }
  }

  &__carousel-area {
    width: 100%;
    margin-bottom: 40px;
    @media screen and (max-width: @bp-mobile-small) {
      margin-bottom: 32px !important;;
    }
    position: relative;
  }

  &__carousel {
    overflow: hidden;

    :global(.hooper-slide) {
      display: flex;
      justify-content: center;
      margin: auto;
    }

    :global(.hooper-list) {
      padding: 0;
    }
  }

  &__carousel-image {
    object-fit: cover;
    height: 320px;
  }

  &__carousel-button {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 1;

    @media screen and (max-width: 1200px) {
      min-width: auto;
    }

    @media screen and (max-width: 754px) {
      display: none !important;
    }

    &_left { left: 0; }
    &_right { right: 0; }
  }
}
</style>
