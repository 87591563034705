var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        stretched: _vm.stretched,
        paddingY: _vm.paddingY,
        bgColor: _vm.bgColor,
        bgImage: _vm.bgImage,
        bgOpacity: _vm.bgOpacity,
    },false),[_c('div',{class:( _obj = {}, _obj[_vm.$style['block']] = true, _obj[_vm.$style['block_inverted']] = _vm.inverted, _obj )},[_c('h1',{class:[_vm.$style['block__title'], 'sw-title'],domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('div',{class:[_vm.$style['block__text'], 'sw-text'],domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._v(" "),_c('div',{class:_vm.$style['block__carousel-area']},[(_vm.images && _vm.images.length)?_c('CarouselRoot',{ref:"carousel",class:_vm.$style['block__carousel'],staticStyle:{"height":"auto","max-height":"320px"},attrs:{"settings":{
                    infiniteScroll: true,
                    itemsToShow: 1,
                    keysControl: false,
                    mouseDrag: true,
                    transition: 400,
                    wheelControl: false
                }}},_vm._l((_vm.images),function(src,index){return _c('CarouselSlide',{key:index,attrs:{"index":index}},[_c('SwImage',{class:_vm.$style['block__carousel-image'],attrs:{"src":src,"height":"320"}})],1)}),1):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length > 1)?[_c('SwButton',{class:[_vm.$style['block__carousel-button'], _vm.$style['block__carousel-button_left']],attrs:{"small":""},on:{"click":function($event){return _vm.$refs.carousel.slidePrev()}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteArrowLeft')]},proxy:true}],null,false,4229751787)}),_vm._v(" "),_c('SwButton',{class:[_vm.$style['block__carousel-button'], _vm.$style['block__carousel-button_right']],attrs:{"small":""},on:{"click":function($event){return _vm.$refs.carousel.slideNext()}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteArrowRight')]},proxy:true}],null,false,4051955344)})]:_vm._e()],2),_vm._v(" "),(_vm.noIndex)?[_c('client-only',[_c('nofollow',[_c('noindex',[_c('span',{class:_vm.$style['block__button']},[(_vm.buttonUrl.includes('//'))?_c('SwButton',{attrs:{"href":_vm.buttonUrl,"target":"_blank"}},[_vm._v("\n                                "+_vm._s(_vm.buttonLabel)+"\n                            ")]):_c('SwButton',{attrs:{"to":_vm.buttonUrl}},[_vm._v("\n                                "+_vm._s(_vm.buttonLabel)+"\n                            ")])],1)])],1)],1)]:_c('span',{class:_vm.$style['block__button']},[(_vm.buttonUrl.includes('//'))?_c('SwButton',{attrs:{"href":_vm.buttonUrl,"target":"_blank"}},[_vm._v("\n                "+_vm._s(_vm.buttonLabel)+"\n            ")]):_c('SwButton',{attrs:{"to":_vm.buttonUrl}},[_vm._v("\n                "+_vm._s(_vm.buttonLabel)+"\n            ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }